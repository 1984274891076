<template>
    <div>
        <h1 class="page__title">FAQ</h1>

        <div class="intro-y box p-5 mt-5">
            <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-name" class="form-label">Название <sup>*</sup></label>
                    <input
                        id="form-name"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.name"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-order" class="form-label">Позиция сортировки <sup>*</sup></label>
                    <input
                        id="form-order"
                        type="number"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.order"
                    />
                </div>

                <div class="col-span-12">
                    <label class="form-label">Описание <sup>*</sup></label>
                    <CustomWyswig v-model="form.body" />
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link to="/faq" class="btn btn-secondary w-24 mb-2 mr-2"> Отмена </router-link>
                    <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(false)">
                        Сохранить и выйти
                    </button>
                    <button type="submit" class="btn btn-primary mb-2" @click.prevent="save(true)">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { errorResponse, formPreparation } from '@/mixins/form/form-mixin';
import Preloader from '@/components/preloader/Main';
import UploadImage from '@/components/upload-image/UploadImage';
import CustomWyswig from '@/components/base/custom-wyswig/Main.vue';

export default {
    name: 'Edit',
    components: { Preloader, UploadImage, CustomWyswig },
    mixins: [errorResponse, formPreparation],
    data() {
        return {
            loading: false,
            form: {
                name: '',
                body: '',
                order: '',
            },
        };
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            this.loading = true;
            try {
                const { data } = await this.axios.get(`/faqs/${this.$route.params.id}`);

                for (const field in this.form) {
                    this.form[field] = data.data[field];
                }

                this.loading = false;
            } catch (ex) {
                console.error('cant find data: ' + ex);
                this.loading = false;
                this.$router.push('/error-page');
            }
        },
        async save(flag) {
            try {
                const formData = this.formPreparation();

                await this.axios.post('/faqs/' + this.$route.params.id, formData, {
                    params: {
                        _method: 'put',
                    },
                });
                if (flag) {
                    this.$notify('Запись сохранена');
                } else {
                    await this.$router.push({ name: 'news' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            }
        },
    },
};
</script>

<style scoped lang="scss"></style>
